<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <router-view ref="settingChild" />
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="editPage !== 'info'">
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'ShopSettingInfo' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-blue" :handleSubmit="() => register(editPage)" buttonText="編集する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//components
import ActionButton from '../../../components/ActionButton.vue';

export default {
  components: { ActionButton },
  name: 'ShopSetting',
  data: function() {
    return {
      pageName: '店舗設定',
      editPage: 'info',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
  },
  methods: {
    register(page) {
      if (page === 'default') {
        const defaultForm = this.$refs.settingChild.settingDefault();
        this.updateShop(defaultForm, 'updateDefaultConfig', 'settingDefault');
      } else if (page === 'base') {
        const baseForm = this.$refs.settingChild.settingBase();
        this.updateShop(baseForm, 'updateBaseConfig', 'settingBase');
      } else if (page === 'detail') {
        const detailForm = this.$refs.settingChild.settingDetail();
        this.updateShop(detailForm, 'updateDetailConfig', 'settingDetail');
      }
    },
    async updateShop(form, path, messageKey) {
      if (form) {
        const result = await this.$store.dispatch(`shop/${path}`, {
          form: form,
          subdomain: this.subdomain,
          shopId: this.shopId,
        });
        if (result) {
          await this.$router.push({ name: 'ShopSettingInfo' });
          this.$message.updated(messageKey);
        }
      }
    }
  },
};
</script>
